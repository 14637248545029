<div class="drugDetails">
  <div class="close-cross">
    <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="loading" *ngIf="isLoading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
    {{ "common.loading" | translate }}
  </div>
  <ng-container *ngIf="!isLoading">
    <app-drug-graph [drug]="data.drug" [notifications]="data.notifications" [patientId]="data.patientId"></app-drug-graph>
    <table class="datatable">
      <thead>
        <th>{{ "drugDetails.date" | translate }}</th>
        <th>{{ "drugDetails.time" | translate }}</th>
        <th>{{ "drugDetails.plannedQuantity" | translate }}</th>
        <th>{{ "drugDetails.status" | translate }}</th>
        <th>{{ "drugDetails.administeredQuantity" | translate }}</th>
        <th>{{ "drugDetails.comment" | translate }}</th>
        <th>{{ "table.author" | translate }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let drugUse of selectedSection; let i = index">
          <tr *ngIf="drugUse.time && drugUse.time !== ''">
            <td class="w-100">{{ drugUse.time | date : "dd/MM/yyyy" }}</td>
            <td
              class="w-100"
              *ngIf="!drugUse.moment || drugUse.status !== NOTIFICATION_STATUS.NONE"
              style="display: flex; align-items: center"
            >
              {{ drugUse.time | date : "HH:mm" }}
              <mat-icon
                *ngIf="drugUse.unscheduledIntake"
                [matTooltip]="'drugIntake.unscheduledIntake' | translate"
                class="unscheduledIntake"
                color="primary"
              >
                calendar_today
              </mat-icon>
            </td>
            <td class="w-100" *ngIf="drugUse.moment && drugUse.status === NOTIFICATION_STATUS.NONE">
              {{ "mydrugs." + drugUse.moment | translate }}
            </td>
            <td>
              {{
                drugUse.quantity
                  ? (drugUse.quantity | displayQuantity)
                  : (data.drug.entityData.frequency.quantities[drugUse.moment] | displayQuantity)
              }}
            </td>
            <td class="w-80">
              <mat-icon class="fail" *ngIf="drugUse.status === NOTIFICATION_STATUS.REJECTED">cancel</mat-icon>
              <mat-icon
                class="success"
                *ngIf="drugUse.status === NOTIFICATION_STATUS.ACCEPTED && drugUse.quantityTaken === drugUse.quantity"
              >
                done
              </mat-icon>
              <mat-icon class="warn" *ngIf="drugUse.status === NOTIFICATION_STATUS.ACCEPTED && drugUse.quantityTaken !== drugUse.quantity">
                done
              </mat-icon>
              <mat-icon class="warn" *ngIf="drugUse.status === NOTIFICATION_STATUS.NONE">help_outline</mat-icon>
              <mat-icon class="fail" *ngIf="drugUse.status === NOTIFICATION_STATUS.DELETED">delete</mat-icon>
              <mat-icon *ngIf="drugUse.device?.reference" color="primary" [matTooltip]="drugUse.device?.reference"> bluetooth </mat-icon>
            </td>
            <td class="warn" *ngIf="drugUse.quantityTaken !== drugUse.quantity">
              {{ drugUse?.quantityTaken ? (drugUse.quantityTaken | displayQuantity) : "" }}
            </td>
            <td class="success" *ngIf="drugUse.quantityTaken === drugUse.quantity && !drugUse.unscheduledIntake">
              {{ drugUse?.quantityTaken ? (drugUse.quantityTaken | displayQuantity) : "" }}
            </td>
            <td class="w-200">{{ drugUse.comment }}</td>
            <td>{{ drugUse.author?.display ? drugUse.author.display : drugUse.status === 0 ? "" : ("table.patient" | translate) }}</td>
            <td>
              <button
                mat-icon-button
                matTooltip="{{ 'drugIntake.addIntake' | translate }}"
                *ngIf="('dashboard/drugIntake' | isAuthorized : 'POST' | async) === true && drugUse.status === 0 && patientConfig"
                aria-label="icon button with a pencil icon"
                (click)="onAddIntake(drugUse, i)"
              >
                <mat-icon class="custom-icon-w baseline-align" color="primary">create</mat-icon>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="action-container">
      <button mat-button [disabled]="isFirstSection()" (click)="changePage('-')">
        {{ "btn.previousData" | translate }}
      </button>
      <p>{{ page + 1 + "/" + nbSection }}</p>
      <button mat-button [disabled]="isLastSection()" (click)="changePage('+')">
        {{ "btn.nextData" | translate }}
      </button>
    </div>
  </ng-container>
</div>
